export const guideColors = {
    primary: '#001518',
    primaryAlpha: 'rgba(75, 182, 191, 0.1)',
    secondary: '#fefefe',
    secondaryAlpha: 'rgba(92, 166, 240, 0.1)',

    black: '#001518',

    darkGrey: '#808080',

    bgGrey: '#f6f7f9',
    grey: '#cbcbcb',

    white: '#fefefe',

    borderBlueGrey: '#ccd5e4',

    red: '#e94c8a',
    yellow: '#ead164',
    purple: '#787eff',
    orange: '#feba57',
    borderBlueLight: '#bad0e5',
    appleGreen: '#7ed321',

    navy: '#011d28',
    lightBlue: '#c0daf4',
    veryLightBlue: '#eff2f7',
    veryLightBlueAlpha: 'rgba(186, 208, 229, .5)',

    // TODO: use red and alpha
    graphColors: ['#d33178', '#e06ea0', '#e998bb', '#f1c1d6', '#faeaf1', '#dbdcdc']
};

export function hexToRgb(hex, alpha) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
        : null;
}

export default guideColors;