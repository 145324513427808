import englishLanguage from './english';
import hebrewLanguage from './hebrew';

class LanguageHandler {
    getLanguageClass(language) {
        if (!language) return englishLanguage;

        switch (language.toLowerCase()) {
            case 'israel' :
            case 'hebrew' :
            case 'he' : {
                return hebrewLanguage;
            }

            default: {
                return englishLanguage;
            }
        }
    }
}

export default new LanguageHandler();