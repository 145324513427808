import React from 'react';

import {Box, Grid, makeStyles, Typography} from '@material-ui/core';

import doneLogo from '../done.png';

const useStyles = makeStyles(theme => ({
    doneLogo: {
        width: theme.spacing(12.5),
        height: theme.spacing(12.5)
    },
    logo: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        cursor: 'pointer'
    },
    msgBox: {
        backgroundColor: theme.palette.guide.white,
        width: '85%',
        border: 1,
        borderColor: theme.palette.guide.black,
        borderStyle: 'solid',
        margin: 'auto',
        padding: theme.spacing(2)
    }
}));

export default function SuccessfulUpdate({subscriptionManager, languagePage}) {
    const classes = useStyles();

    return (
        <Grid container className={classes.msgBox}>
            <Grid item xs={12}>
                <Box mt={5} textAlign={'center'}>
                    <Box textAlign="center">
                        <Box my={3}>
                            <img src={doneLogo} className={classes.doneLogo} alt={''}/>
                        </Box>

                        <Typography variant="h4" dir={languagePage.config.direction}>
                            {languagePage.getSuccessfulUnsubscribeMsg(subscriptionManager)}
                        </Typography>

                        <Box mt={5}/>

                        {subscriptionManager && subscriptionManager.userLists.filter(ul => ul.status === 'active').length !== 0 && subscriptionManager.userLists.filter(ul => ul.status === 'unsubscribed').map((ul, i) => (
                            <Box key={i} mt={2} dir={languagePage.config.direction}>
                                <Typography variant="h4">{ul.displayName || ul.userListName}</Typography>
                            </Box>
                        ))}

                        <Box mt={7.5} mb={2.5} dir={languagePage.config.direction}>
                            <Typography variant="h4">
                                {languagePage.changesWillTakeEffect}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}