import guideColors from './theme-palette';
import {responsiveFontSize} from './theme-typography';

const muiButton = theme => ({
    MuiButton: {
        root: {
            borderRadius: theme.spacing(3),
            padding: theme.spacing(.5, 1.5),
            minWidth: theme.spacing(12.5)
        },
        label: {
            fontWeight: 600,
            whiteSpace: 'nowrap',
            fontFamily: 'Arial !important',
            textTransform: 'uppercase',
            ...responsiveFontSize(theme, 'button')
        },
        textSizeSmall: {
            '& $label': {
                fontSize: theme.typography.pxToRem(12)
            }
        },
        contained: {
            boxShadow: 'none',

            '&$disabled': {
                backgroundColor: guideColors.veryLightBlue
            },
            '& $label': {
                textTransform: 'none'
            }
        },
        outlined: {
            '& $label': {
                textTransform: 'none'
            }
        },
        containedPrimary: {
            '& $label': {
                color: guideColors.white
            }
        },
        containedSecondary: {
            '& $label': {
                color: guideColors.white
            }
        }
    }
});

export default muiButton;