const fs = {
    h1: 32,
    h2: 28,
    h3: 24,
    h4: 20,
    h5: 18,
    h6: 18,
    body1: 16,
    button: 16,
    caption: 12
};

export const responsiveFontSize = (theme, variant) => ({
    fontSize: theme.typography.pxToRem(fs[variant] * 0.65),
    [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(fs[variant] * 0.75)
    },
    [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(fs[variant] * 0.875)
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: theme.typography.pxToRem(fs[variant])
    }
});

const muiTypography = theme => ({
    MuiTypography: {
        root: {
            fontFamily: 'Arial !important',
            lineHeight: 'normal !important'
        },
        h1: {
            fontWeight: 600,
            ...responsiveFontSize(theme, 'h1')
        },
        h2: {
            fontWeight: 700,
            ...responsiveFontSize(theme, 'h2')
        },
        h3: {
            fontWeight: 600,
            ...responsiveFontSize(theme, 'h3')
        },
        h4: {
            fontWeight: 600,
            ...responsiveFontSize(theme, 'h4')
        },
        h5: {
            fontWeight: 700,
            ...responsiveFontSize(theme, 'h5')
        },
        h6: {
            fontWeight: 600,
            ...responsiveFontSize(theme, 'h6')
        },
        subtitle1: {
            fontWeight: 600,
            textTransform: 'uppercase',
            fontSize: theme.typography.pxToRem(fs.caption) // used for TH
        },
        body1: {
            fontWeight: 400,
            ...responsiveFontSize(theme, 'body1')
        },
        button: {
            fontWeight: 600,
            ...responsiveFontSize(theme, 'button')
        },
        caption: {
            fontSize: theme.typography.pxToRem(fs.caption) // fixed font size, smaller than 12px will be hard to read
        }
    }
});

export default muiTypography;