import {useState} from 'react';

import queryString from 'query-string';

import apiClient from '../api-client';

export default function useSubscriptionManager() {
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const [unsubscribeSuccessfully, setUnsubscribeSuccessfully] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [userLists, setUserLists] = useState([]);
    const query = queryString.parse(window.location.search);

    const loadUnsubscribeUserLists = async () => {
        let {token, userListChannel, widgetId, userListId} = query;

        userListChannel = userListChannel.replace('Journey', '');

        token = decodeURIComponent(token).replace(new RegExp(' ', 'g'), '+');

        try {
            const response = await apiClient.getSubscriptionData({token, userListChannel, widgetId});

            if (response.unsubscribeAll) {
                setUnsubscribeSuccessfully(true);

                return;
            }

            let customerUserLists = response.customerUserLists;

            setCompanyData({
                companyId: response.companyId,
                companyName: response.companyName,
                siteUrl: response.siteUrl,
                logoImageUrl: response.siteLogo,
                customerPhone: response.customerPhone,
                customerEmail: response.customerEmail,
                customerFirstName: response.firstName || '',
                userListChannel,
                languagePage: response.unsubscribeLanguage || 'en'
            });

            if (customerUserLists.length > 0) {
                findAndOrderUserListsArray(userListId, customerUserLists);

                const userListsWithInitialStatus = customerUserLists
                    .map(userList => ({...userList, initStatus: userList.status}));

                setUserLists(userListsWithInitialStatus);
            }
        } catch (e) {
            console.log(e);
            setServerError('An error has occurred. Please disable AdBlock and refresh the page.');
        } finally {
            setIsLoading(false);
        }
    };

    const subscribeToAllUserLists = async () => {
        const newUserLists = userLists.map(userList => ({...userList, status: 'active'}));

        setUserLists(newUserLists);

        await updateUserListSubscriptions(newUserLists);
    };

    const updateUserListSubscriptions = async (userListsToUpdate) => {
        try {
            setIsLoading(true);

            const {token, widgetId, actionId, creationTime, userId} = query;

            const response = await apiClient.updateSubscriptionData({
                companyId: companyData.companyId,
                token,
                widgetId,
                actionId,
                creationTime,
                userId,
                userListChannel: companyData.userListChannel,
                serviceUserId: companyData.userListChannel && companyData.userListChannel.toLowerCase() === 'sms' ? companyData.customerPhone : companyData.customerEmail,
                userListsSubscriptions: userListsToUpdate.map(userList => ({
                    id: userList.userListId,
                    status: userList.status
                }))
            });

            if (response.status !== 200) {
                setServerError('An error occurred');
            }
            setUnsubscribeSuccessfully(!unsubscribeSuccessfully);
        } catch (e) {
            setServerError('An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    const toggleUserListStatus = (userListId) => {
        const newUserLists = userLists
            .map(userList => {
                if (userList.userListId === userListId) {
                    userList.status = userList.status === 'active' ? 'unsubscribed' : 'active';
                }

                return userList;
            });

        setUserLists(newUserLists);
    };

    const revertUserListSubscriptions = async () => {
        const newUserLists = userLists
            .map(userList => ({...userList, status: userList.initStatus}));

        setUserLists(newUserLists);

        await updateUserListSubscriptions(newUserLists);
    };

    const unsubscribeAllUserLists = async () => {
        const newUserLists = userLists
            .map(userList => ({...userList, status: 'unsubscribed'}));

        setUserLists(newUserLists);

        await updateUserListSubscriptions(newUserLists);
    };

    function findAndOrderUserListsArray(userListId, customerUserLists) {
        // find userListId and shift it to the top of the list
        try {
            if (userListId) {
                const index = customerUserLists.findIndex(ul => ul.userListId === parseInt(userListId));

                if (index > -1) {
                    const splicedUserList = customerUserLists.splice(index, 1);

                    customerUserLists.unshift(...splicedUserList);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function createUnsubscribedMember() {
        try {
            setIsLoading(true);

            const {token, widgetId, actionId, creationTime, userId, userListId, userListChannel} = query;

            const response = await apiClient.createUnsubscribedMember({
                companyId: companyData.companyId,
                token,
                widgetId,
                actionId,
                creationTime,
                userId,
                userListChannel,
                serviceUserId: companyData.userListChannel && companyData.userListChannel.toLowerCase() === 'sms' ? companyData.customerPhone : companyData.customerEmail,
                userListsSubscriptions: [{
                    id: userListId,
                    status: 'unsubscribed'
                }]
            });

            if (response.status !== 200) {
                setServerError('An error occurred');
            }

            setUnsubscribeSuccessfully(!unsubscribeSuccessfully);
        } catch (e) {
            setServerError('An error occurred');
        } finally {
            setIsLoading(false);
        }
    }

    return {
        createUnsubscribedMember,
        loadUnsubscribeUserLists,
        subscribeToAllUserLists,
        updateUserListSubscriptions,
        toggleUserListStatus,
        revertUserListSubscriptions,
        unsubscribeAllUserLists,
        serverError,
        unsubscribeSuccessfully,
        isLoading,
        userLists,
        companyData
    };
}