class HebrewLanguage {
    config = {
        direction: 'rtl'
    }

    noAdditionalPromotion = 'אם תסיר/י עצמך מרשימת הדיוור, לא נוכל לשתף אותך במבצעים ובפרסומים חדשים.'
    insteadPromotion = 'לחילופין ביכולתך לעדכן את שאר הגדרות הדיוור:'
    changesWillTakeEffect = 'השינויים ייכנסו לתוקף במהלך 48 השעות הקרובות.';
    removeMe = 'הסירו אותי';
    removeFromAllList = 'הסרה מכל הרשימות';
    updatePreferences = 'עדכון ושמירת הגדרות';
    noUserListsFound = 'רשימת דיוור לא נמצאה';
    sorryToSeeYouGo = 'האם ברצונך להסיר את עצמך מרשימה זו?';
    unsubscribeSuccess = 'הכתובת הוסרה בהצלחה מכל דיוור שיווקי עתידי.';
    sorryToSeeYouGoAll = 'האם ברצונך להסיר את עצמך מרשימה זו?';

    areYouSureYouWantToUnsubscribe(subscriptionManager) {
        let str = '';

        if (subscriptionManager.companyData.userListChannel === 'Email') {
            str += subscriptionManager.companyData.customerEmail;
        } else {
            str += subscriptionManager.companyData.customerPhone;
        }
        str += subscriptionManager.companyData.userListChannel === 'Email' ? ', כתובת המייל רשומה' : ', מספר הטלפון רשום';
        str += ' לקבלת דיוור, האם ברצונך להסיר את עצמך מרשימה זו?';

        return str;
    }

    getSuccessfulUnsubscribeMsg(subscriptionManager) {
        let successfullyMsg;

        if (!subscriptionManager || subscriptionManager.userLists.length === 0) {
            return this.unsubscribeSuccess;
        }

        if (subscriptionManager.companyData.userListChannel === 'Email') {
            successfullyMsg = 'כתובת המייל ' + `${subscriptionManager.companyData.customerEmail}` + ' הוסרה בהצלחה ';
        } else {
            successfullyMsg = 'הטלפון ' + `${subscriptionManager.companyData.customerPhone}` + ' הוסר בהצלחה ';
        }

        successfullyMsg += subscriptionManager.userLists.filter(ul => ul.status === 'active').length === 0
            ? 'מכל דיוור שיווקי עתידי.'
            : `מרשימ${subscriptionManager.userLists.filter(ul => ul.status === 'unsubscribed').length > 1 ? 'ות  הדיוור:' : 'ת  הדיוור:'}`;

        return successfullyMsg;
    }
}

export default new HebrewLanguage();