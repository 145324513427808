import {createTheme} from '@material-ui/core/styles';

import muiButton from './theme-button';
import muiInput from './theme-input';
import guideColors from './theme-palette';
import muiTypography, {responsiveFontSize} from './theme-typography';

const theme = createTheme();

export default createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1350,
            lg: 1500,
            xl: 1920
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: guideColors.primary
        },
        secondary: {
            main: guideColors.secondary
        },
        background: {
            default: guideColors.bgGrey
        },
        error: {
            main: guideColors.red
        },
        guide: guideColors
    },
    mixins: {
        drawer: {
            width: '240px'
        },
        topBar: {
            height: theme.spacing(10)
        },
        mailto: {
            textDecoration: 'none',
            color: guideColors.red
        },
        removeNumberInputArrows: {
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none'
            },
            '-moz-appearance': 'textfield'
        }
    },
    props: {
        MuiExpansionPanel: {
            elevation: 0
        }
    },
    overrides: {
        MuiChip: {
            label: {
                fontFamily: 'Nunito'
            }
        },
        MuiPaper: {
            root: {
                '&$rounded': {
                    borderRadius: theme.spacing(2.5)
                }
            }
        },
        MuiTab: {
            root: {
                textTransform: 'none'
            }
        },
        MuiSnackbar: {
            root: {
                position: 'relative !important',
                top: '70px !important'
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: 20
            }
        },
        MuiPopover: {
            paper: {
                borderRadius: `${theme.spacing(0.5)}px !important`
            }
        },
        MuiAccordionSummary: {
            root: {
                '&$focused': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiTooltip: {
            arrow: {
                color: guideColors.yellow
            },
            tooltip: {
                backgroundColor: guideColors.yellow,
                color: theme.palette.text.primary,
                fontFamily: theme.typography.body1.fontFamily,
                fontWeight: theme.typography.body1.fontWeight,
                ...responsiveFontSize(theme, 'body1'),
                lineHeight: theme.typography.body1.lineHeight,
                padding: theme.spacing(1.5, 2),
                boxShadow: theme.shadows[4]
            }
        },
        ...muiInput(theme),
        ...muiButton(theme),
        ...muiTypography(theme)
    }
});