import React from 'react';

import {Box, Container, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    logo: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        cursor: 'pointer'
    },
    logoImg: {
        display: 'block',
        margin: '0 auto',
        width: '300px'
    },
    container: {
        maxWidth: '100%',
        padding: theme.spacing(4, 7),
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2, 3.5)
        }
    }
}));

export function PageBase({subscriptionManager, children}) {
    const classes = useStyles();

    return (
        <Container className={classes.container} >
            <Box className={classes.logo}>
                <a href={subscriptionManager.companyData.siteUrl}>
                    <img alt="" src={subscriptionManager.companyData.logoImageUrl} className={classes.logoImg}/>
                </a>
            </Box>
            {children}
        </Container>
    );
}