import guideColors from './theme-palette';
import {responsiveFontSize} from './theme-typography';

const muiInput = theme => ({
    MuiInputBase: {
        input: {
            fontFamily: 'Nunito',
            fontWeight: 600,
            ...responsiveFontSize(theme, 'h4')
        },
        inputMultiline: {
            lineHeight: 1.5
        },
        multiline: {
            '& button': {
                paddingRight: 0
            }
        },
        root: {
            fontFamily: 'Nunito',
            '&:hover svg.MuiSelect-icon': {
                color: guideColors.secondary
            },
            '& button:hover': {
                background: 'none'
            }
        }
    },
    MuiInput: {
        underline: {
            '&:before': {
                borderBottomColor: guideColors.lightBlue
            },
            '&:hover': {
                '&:before': {
                    borderBottomColor: `${guideColors.lightBlue} !important`
                }
            }
        },
        input: {
            ...responsiveFontSize(theme, 'body1')
        }
    },
    MuiOutlinedInput: {
        root: {
            background: guideColors.white,

            '&:hover $notchedOutline': {
                borderColor: guideColors.lightBlue
            }
        },
        input: {
            fontWeight: 600,
            fontFamily: 'Nunito',
            padding: theme.spacing(1.75, 2.25),
            ...responsiveFontSize(theme, 'body1')
        },
        inputMarginDense: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        notchedOutline: {
            borderColor: guideColors.lightBlue
        }
    },
    MuiSelect: {
        selectMenu: {
            minHeight: 0
        },
        iconOutlined: {
            right: 12
        },
        outlined: {
            background: guideColors.white,
            minWidth: `${theme.spacing(20)}px !important`,

            '&:focus': {
                background: guideColors.white
            }
        },
        icon: {
            color: guideColors.black
        }
    },
    MuiInputLabel: {
        root: {
            fontFamily: 'Nunito',
            fontSize: theme.typography.pxToRem(12)
        }
    },
    MuiFormHelperText: {
        contained: {
            marginLeft: 0,
            marginRight: 0
        }
    }
});

export default muiInput;