import React from 'react';

import {MuiThemeProvider} from '@material-ui/core/styles';

import AdikaUnsubscribePage from './adika-unsubscribe-page/adika-unsubscribe-page';
import theme from './app-theme/theme';
import SubscriptionManager from './subscription-manager/SubscriptionManager';

export default function App() {
    //  Test adika with console command "localStorage.adika = 1;"
    const isAdikaUnsubscribe = localStorage.adika === '1' || window.location.href.includes('//adika.');

    return (
        <MuiThemeProvider theme={theme}>
            {isAdikaUnsubscribe ? <AdikaUnsubscribePage/> : <SubscriptionManager/>}
        </MuiThemeProvider>
    );
}