
class EnglishLanguage {
     config = {
         direction: 'ltr'
     }

     noAdditionalPromotion = 'If you unsubscribe, we will no longer be able to share the latest news, promotions, and sales with you.'
     insteadPromotion = ' Instead, you can change your communication preferences:';
     changesWillTakeEffect = 'The changes will take effect over the next 48 hours.';
     removeMe = 'Unsubscribe';
     removeFromAllList = 'Unsubscribe all';
     updatePreferences = 'Update my preferences';
     noUserListsFound = 'No user lists found';
     sorryToSeeYouGo = 'we’re sorry to see you go! Are you sure you want to unsubscribe from the list below?';
     sorryToSeeYouGoAll = 'We’re sorry to see you go! Are you sure you want to unsubscribe?';
     unsubscribeSuccess = 'You have been successfully removed from any future marketing lists';

     areYouSureYouWantToUnsubscribe(subscriptionManager){
         if (subscriptionManager.companyData.userListChannel === 'Email'){
             return `${subscriptionManager.companyData.customerEmail}, ${this.sorryToSeeYouGo}`;
         }

         return `${subscriptionManager.companyData.customerPhone}, ${this.sorryToSeeYouGo}`;
     }

     getSuccessfulUnsubscribeMsg(subscriptionManager) {
         if (!subscriptionManager || subscriptionManager.userLists.length === 0) {
             return this.unsubscribeSuccess;
         }

         let successfullyMsg ='';

         if (subscriptionManager.companyData.userListChannel === 'Email'){
             successfullyMsg +=`${subscriptionManager.companyData.customerEmail} was successfully removed from`;
         }else{
             successfullyMsg +=`${subscriptionManager.companyData.customerPhone} was successfully removed from`;
         }

         successfullyMsg += `${
             subscriptionManager.userLists.filter(ul => ul.status === 'active').length === 0 ?
                 ' any future marketing lists':':'}`;

         return successfullyMsg;
     }
}
export default new EnglishLanguage();