import React, {useEffect, useRef} from 'react';

import {Box, Button, Checkbox, Grid, Link, makeStyles, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import {PageBase} from './components/PageBase';
import SuccessfulUpdate from './components/SuccessfulUpdate';
import languageHandler from './languages/language-handler';
import useSubscriptionManager from './use-subscription-manager';

const useStyles = makeStyles(theme => ({
    spinner: {
        margin: '0 auto',
        display: 'block',
        marginTop: theme.spacing(10)
    },
    container: {
        textAlign: 'center'
    },
    checkBoxWrapper: {
        marginTop: theme.spacing(5),
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'center',
        width: '60%',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    userListName: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        }
    },
    btnBox: {
        width: theme.spacing(25),
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    },
    updatePreferencesButton: {
        width: theme.spacing(36),
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    },
    alertBox: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.guide.white,
        background: theme.palette.guide.red,
        borderRadius: theme.spacing(.5),
        padding: theme.spacing(1.5, 2),
        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)'
    },
    icon: {
        fontSize: theme.typography.pxToRem(32),
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        flexShrink: 0,
        userSelect: 'none'
    },
    msgBox: {
        backgroundColor: theme.palette.guide.white,
        width: '85%',
        border: 1,
        borderColor: theme.palette.guide.black,
        borderStyle: 'solid',
        margin: 'auto',
        padding: theme.spacing(2)
    }
}));

function Loader() {
    const classes = useStyles();

    return (
        <CircularProgress className={classes.spinner} color="primary" size={40}/>
    );
}

function AlertMessage({errorMessage}) {
    const classes = useStyles();

    return (
        <Box className={classes.alertBox}>
            <Box mr={1} display="flex">
                <svg className={classes.icon} focusable="false" viewBox="0 0 24 24"
                     aria-hidden="true">
                    <path
                        d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                </svg>
            </Box>

            <Typography variant="h4" color="inherit">{errorMessage}</Typography>
        </Box>
    );
}

export default function SubscriptionManager() {
    const classes = useStyles();

    const subscriptionManager = useSubscriptionManager();
    const languagePage = languageHandler.getLanguageClass(subscriptionManager.companyData.languagePage);
    const subscriptionManagerRef = useRef(subscriptionManager).current;

    useEffect(() => {
        async function loadData() {
            await subscriptionManagerRef.loadUnsubscribeUserLists();
        }

        loadData();
    }, [subscriptionManagerRef]);

    if (subscriptionManager.isLoading) {
        return (
            <PageBase subscriptionManager={subscriptionManager}>
                <Loader/>
            </PageBase>
        );
    }

    if (subscriptionManager.serverError) {
        return (
            <PageBase subscriptionManager={subscriptionManager}>
                <AlertMessage
                    errorMessage={subscriptionManager.serverError}
                />
            </PageBase>
        );
    }

    const userLists = subscriptionManager.userLists;

    if (subscriptionManager.unsubscribeSuccessfully) {
        return (
            <PageBase subscriptionManager={subscriptionManager}>
                <SuccessfulUpdate
                    subscriptionManager={subscriptionManager}
                    languagePage = {languagePage}
                />
            </PageBase>
        );
    }

    return (
        <PageBase subscriptionManager={subscriptionManager} >
            <Box mt={5}/>

            <Grid container className={classes.msgBox} dir={languagePage.config.direction}>
                <Grid item xs={12}>
                    {!userLists.length
                        ? (
                            <>
                                <Box mt={3} textAlign={'center'}>
                                    <Typography variant="h4">
                                        {languagePage.sorryToSeeYouGoAll}
                                    </Typography>
                                </Box>

                                <Box display={'flex'} mt={5} justifyContent={'center'}>
                                    <Box className={classes.updatePreferencesButton} pr={2}>
                                        <Button
                                            onClick={() => subscriptionManager.createUnsubscribedMember()}
                                            variant="contained"
                                            fullWidth
                                            color={'primary'}
                                        >
                                            {languagePage.removeMe}
                                        </Button>

                                        <Box mt={3}/>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box mt={3} textAlign={'center'}>
                                    <Typography variant="h4">
                                        {languagePage.areYouSureYouWantToUnsubscribe(subscriptionManager)}
                                    </Typography>
                                </Box>

                                <Box mt={2} textAlign={'center'}>
                                    <Typography variant="h4">
                                        {userLists.length > 0 && userLists[0].displayName || userLists[0].userListName}
                                    </Typography>
                                </Box>

                                <Box display={'flex'} mt={5} justifyContent={'center'}>
                                    <Box className={classes.updatePreferencesButton} pr={2}>
                                        <Button
                                            onClick={() => subscriptionManager.updateUserListSubscriptions([{
                                                ...subscriptionManager.userLists[0],
                                                status: 'unsubscribed'
                                            }])}
                                            variant="contained"
                                            fullWidth
                                            color={'primary'}
                                        >
                                            {languagePage.removeMe}
                                        </Button>

                                        <Box mt={3}/>
                                    </Box>
                                </Box>
                            </>
                        )
                    }
                </Grid>
            </Grid>

            {userLists.length <= 1 ?
                <></>
                :
                <Grid container className={classes.container} dir={languagePage.config.direction}>
                    <Grid item xs={12}>
                        <Box mt={5}/>

                        <Typography variant="h4">
                            {languagePage.noAdditionalPromotion}
                        </Typography>

                        <Box mt={1}/>

                        <Typography variant="h4">
                            {languagePage.insteadPromotion}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box className={classes.checkBoxWrapper}>
                            <Box>
                                {userLists.map((userList, i) => (
                                    <Box key={i} mt={2} display="flex" alignItems="center">
                                        <Checkbox
                                            size="medium"
                                            onClick={() => subscriptionManager.toggleUserListStatus(userList.userListId)}
                                            checked={userList.status !== 'active'}
                                            color="primary"
                                        />

                                        <Typography variant="h4" className={classes.userListName}>
                                            {userList.displayName || userList.userListName}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mt={5}>
                            <Box className={classes.updatePreferencesButton}>
                                <Button
                                    onClick={() => subscriptionManager.updateUserListSubscriptions(subscriptionManager.userLists)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    {languagePage.updatePreferences}
                                </Button>
                            </Box>

                            <Box mt={2}/>

                            <Link href="#" color="inherit" underline="always"
                                  onClick={() => subscriptionManager.unsubscribeAllUserLists()}
                            >
                                {languagePage.removeFromAllList}
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            }
        </PageBase>
    );
}